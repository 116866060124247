
import Maintenance from "@hd2/common/src/views/Maintenance.vue";
import Loading from "@hd2/common/src/views/Loading.vue";
import { defineComponent, ref, inject, watch, Ref } from "vue";
import { useI18n } from "vue-i18n";
import plPL from "ant-design-vue/es/locale/pl_PL";
import "moment/dist/locale/pl";
import HeaderComponent from "./components/Header.vue";
import moment from "moment";
import { AxiosStatic } from "axios";
//import { useStore } from "./store";
import { useRouter } from "vue-router";

moment.locale("pl");

export const AppComponent = defineComponent({
  components: {
    Maintenance,
    Loading,
    "app-header": HeaderComponent,
  },
  setup() {
    const maintenance = inject("maintenance");
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    //const store = useStore();
    const router = useRouter();

    const loading: Ref<boolean> = ref(false);

    const setup = async () => {
      loading.value = true;
      router.push("/");
      loading.value = false;
    };

    return {
      maintenance: ref(maintenance),
      //store,
      loading,
      t,
      plPL,
    };
  },
});
export default AppComponent;
