<template>
  <div class="header">
    <a-row>
      <a-col class="header__wrapper header__wrapper--mobile" :xs="24" :md="0">
        <a-row>
          <a-col :xs="24">
            <div class="header__left">
              <a-button
                class="header__hamburger"
                type="link"
                @click="visibleMobileMenu = true"
                shape="round"
              >
                <template #icon><MenuOutlined /></template>
              </a-button>

              <div class="header__logo"></div>

              <a-drawer
                placement="left"
                :closable="true"
                class="header__drawer"
                v-model:visible="visibleMobileMenu"
              >
                <div class="header__right">
                  <div class="header__nav">menu mobile</div>
                </div>
              </a-drawer>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col class="header__wrapper header__wrapper--desktop" :md="24" :xs="0">
        <a-row class="header__top" align="middle">
          <a-col :lg="12" :md="12">
            <div class="header__left">
              <div class="header__logo"></div>
              <div class="header__spacer"><span></span></div>
              <div class="header__helpline">
                <a-row justify="center" align="middle">
                  <a-col :span="4">
                    <div class="helpline__icon">
                      <span role="img" aria-label="Phone icon">
                        <PhoneIcon />
                      </span>
                    </div>
                  </a-col>
                  <a-col :span="20">
                    <div class="helpline__description">
                      <a-row>
                        <a-col :span="24">
                          <span class="helpline__description--phone">
                            {{ t("HEADER.HELPLINE.PHONE") }}
                          </span>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="24">
                          <span class="helpline__description--text">
                            {{ t("HEADER.HELPLINE.TEXT") }}
                          </span>
                        </a-col>
                      </a-row>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>

          <a-col :lg="12" :md="12">
            <div class="header__right">
              <a-button type="primary" shape="round" size="large">
                <template #icon>
                  <span role="img" aria-label="User icon"><UserIcon /></span> Zaloguj się
                </template>
              </a-button>
              <!-- <div class="language-switcher">
                <span class="language-switcher__text">
                  {{t("HEADER.LANGUAGE")}}
                </span>
                <span class="language-switcher__icon">
                  <PolandIcon />
                </span>
                <span class="language-switcher__switcher">
                  <a-dropdown>
                    <a class="" @click.prevent>
                      <DownOutlined />
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item>
                          <a @click="switchLanguage('polish')">{{t("HEADER.TRANSLATIONS.POLISH")}}</a>
                        </a-menu-item>
                        <a-menu-item>
                          <a @click="switchLanguage('english')">{{t("HEADER.TRANSLATIONS.ENGLISH")}}</a>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </span>
              </div> -->
            </div>
          </a-col>
        </a-row>
        <!-- <a-row class="header__bottom" align="middle">
          <a-menu class="header__nav" mode="horizontal" :selectable="false">
            menu desktop
          </a-menu>
        </a-row> -->
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts">
import { MenuOutlined } from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { MenuInfo } from "ant-design-vue/lib/menu/src/interface";
import PhoneIcon from "../assets/images/icons/phone.svg?inline";
import UserIcon from "../assets/images/icons/user.svg?inline";
//import PolandIcon from "../assets/images/icons/poland.svg?inline";
//import { DownOutlined } from "@ant-design/icons-vue";

export const HeaderComponent = defineComponent({
  components: {
    MenuOutlined,
    PhoneIcon,
    UserIcon,
//    PolandIcon,
//    DownOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { t } = useI18n();

    const currentPage = ref<string[]>([route.path?.toString()]);

    // const switchLanguage = (language: string) => {
    //   console.log(language);
    // }

    watch(
      () => route.path,
      () => {
        currentPage.value = [route.path?.toString()];
      }
    );

    const visibleMobileMenu = ref(false);

    // const changeRoute = ({ key }: MenuInfo) => {
    //   visibleMobileMenu.value = false;
    //   router.push({ path: key.toString() });
    // };

    return {
      currentPage,
      visibleMobileMenu,
      t,
      //switchLanguage,
    };
  },
});

export default HeaderComponent;
</script>

<style lang="scss" scoped>
.header {
  margin: 0 auto;
  max-width: $container-width;
  padding: 0 10px;

  .header__wrapper {
    &--desktop {
      @media (max-width: 768px) {
        visibility: hidden;
      }
    }

    &--mobile {
      .header__left {
        .header__logo {
          margin: 0 auto;
        }
        .header__logout,
        .header__logout:hover {
          color: $c2;
        }
        .header__hamburger {
          font-size: 2em;
          padding: 0px;
          > span {
            vertical-align: 0.5em;
            color: $c1;
          }
        }
      }
    }

    .header {
      &__top {
        height: 100%;
        max-height: 94px;
      }
      // &__bottom {
      //   height: 100%;
      //   max-height: 60px;
      // }
      &__left {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
      }
      &__right {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .ant-btn-round.ant-btn-lg{
          font-size: 15px;
          display: flex;
          align-items: center;
          margin-right: 15px;
          span{
            display: flex;
            margin-right: 8px;
          }
        }
        .language-switcher{
          display: flex;
          align-items: center;
          &__text{
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            margin-right: 5px;
          }
          &__switcher{
            
          }
        }
      }
      &__logo {
        width: 122px;
        height: 66px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url("~@/assets/images/logo-homedoctor-color.png");
      }
      &__spacer {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: block;
          height: 44px;
          width: 1px;
          background-color: #ebebeb;
        }
      }
      &__helpline {
        max-width: 230px;
        width: 100%;
        .helpline {
          &__description {
            &--phone{
              font-weight: bold;
              font-size: 22px;
              line-height: 28px;

              color: #172C82;
            }
            &--text{
              font-size: 16px;
              line-height: 20px;
              color: #656565;
            }
          }
        }
      }
    }
  }
}
</style>
