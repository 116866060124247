<template>
  <div class="maintenance">
    <a-result status="500" :title="title">
      <template #subTitle>
        <p v-html="description"></p>
      </template>
    </a-result>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export const MaintenanceComponent = defineComponent({
  props: {
    title: {
      type: String,
      default: "Technical break",
    },
    description: {
      type: String,
      default:
        "We're sorry, but we're having some technical difficulties.<br>Please try again later.",
    },
  },
  setup() {
    return {};
  },
});

export default MaintenanceComponent;
</script>
<style lang="scss" scoped>
.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  :deep(.ant-result) {
    @media (min-height: 992px) {
      margin-top: -20vh;
    }
    .ant-result-title {
      padding-top: 20px;
      font-size: 30px;
      line-height: 30px;
    }
    .ant-result-subtitle {
      padding-top: 20px;
      font-size: 20px;
    }
  }
}
</style>
