
import { defineComponent, h } from "vue";
import { Loading3QuartersOutlined } from "@ant-design/icons-vue";

export const LoadingComponent = defineComponent({
  setup() {
    const indicator = h(Loading3QuartersOutlined, {
      style: {
        fontSize: "min(30vw, 300px)",
      },
      spin: true,
    });
    return {
      indicator,
    };
  },
});

export default LoadingComponent;
