<template>
  <div class="loading">
    <a-spin :indicator="indicator" />
  </div>
</template>

<script lang="ts">
import { defineComponent, h } from "vue";
import { Loading3QuartersOutlined } from "@ant-design/icons-vue";

export const LoadingComponent = defineComponent({
  setup() {
    const indicator = h(Loading3QuartersOutlined, {
      style: {
        fontSize: "min(30vw, 300px)",
      },
      spin: true,
    });
    return {
      indicator,
    };
  },
});

export default LoadingComponent;
</script>
<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  :deep(.ant-result) {
    @media (min-height: 992px) {
      margin-top: -20vh;
    }
    .ant-result-title {
      padding-top: 20px;
      font-size: 30px;
      line-height: 30px;
    }
    .ant-result-subtitle {
      padding-top: 20px;
      font-size: 20px;
    }
  }
}
</style>
