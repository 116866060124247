
import { MenuOutlined } from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { MenuInfo } from "ant-design-vue/lib/menu/src/interface";
import PhoneIcon from "../assets/images/icons/phone.svg?inline";
import UserIcon from "../assets/images/icons/user.svg?inline";
//import PolandIcon from "../assets/images/icons/poland.svg?inline";
//import { DownOutlined } from "@ant-design/icons-vue";

export const HeaderComponent = defineComponent({
  components: {
    MenuOutlined,
    PhoneIcon,
    UserIcon,
//    PolandIcon,
//    DownOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { t } = useI18n();

    const currentPage = ref<string[]>([route.path?.toString()]);

    // const switchLanguage = (language: string) => {
    //   console.log(language);
    // }

    watch(
      () => route.path,
      () => {
        currentPage.value = [route.path?.toString()];
      }
    );

    const visibleMobileMenu = ref(false);

    // const changeRoute = ({ key }: MenuInfo) => {
    //   visibleMobileMenu.value = false;
    //   router.push({ path: key.toString() });
    // };

    return {
      currentPage,
      visibleMobileMenu,
      t,
      //switchLanguage,
    };
  },
});

export default HeaderComponent;
