import { render } from "./phone.svg?vue&type=template&id=330c48fa?inline"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "330c48fa"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('330c48fa', script)) {
    api.reload('330c48fa', script)
  }
  
  module.hot.accept("./phone.svg?vue&type=template&id=330c48fa?inline", () => {
    api.rerender('330c48fa', render)
  })

}

script.__file = "src/assets/images/icons/phone.svg"

export default script