import { render } from "./user.svg?vue&type=template&id=702aaf7c?inline"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "702aaf7c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('702aaf7c', script)) {
    api.reload('702aaf7c', script)
  }
  
  module.hot.accept("./user.svg?vue&type=template&id=702aaf7c?inline", () => {
    api.rerender('702aaf7c', render)
  })

}

script.__file = "src/assets/images/icons/user.svg"

export default script